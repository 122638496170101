import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Success from './pages/Success';
import Checkout from './pages/Checkout';
import { useGetStorefrontQuery } from './api/storefrontApiSlice';
import Review from './pages/Review';
import ReviewSuccess from './pages/ReviewSuccess';
import Tracking from './pages/Tracking';
import Detail from './pages/Detail';

function App() {
  const { data: storefront, isLoading, isSuccess } = useGetStorefrontQuery();

  return (
    <>
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <Home
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />

          <Route
            path='/:productUrl'
            element={
              <Detail
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />

          <Route
            path='/checkout/:orderId'
            element={
              <Checkout
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />

          <Route
            path='/order/success/'
            element={
              <Success
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />

          <Route
            path='/order/review/:customerId'
            element={
              <Review
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />

          <Route
            path='/order/reviewsuccess'
            element={
              <ReviewSuccess
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />

          <Route
            path='/order/track/:orderId'
            element={
              <Tracking
                storefront={storefront}
                gettingStorefront={isLoading}
                gotStorefront={isSuccess}
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
