import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import Footer from '../Footer';
import Header from '../Header';
import ReactPaginate from 'react-paginate';

const MobileHome = ({ products, storefront }) => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(products[0]);

  const handleSelectedProduct = (selectedOption) => {
    const productId = selectedOption.value;
    const selected = products.find((product) => product.item._id === productId);

    setSelectedProduct(selected);

    navigate(`/${selected?.item?.url}`);
  };

  //stuff for pagination//
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;

  const endOffset = itemOffset + itemsPerPage;
  const currentProducts = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;

    setItemOffset(newOffset);

    window.scrollTo(0, 0);
  };
  //pagination stuff ends here//
  return (
    <>
      <Header
        storefront={storefront?.storefront}
        sellerProfile={storefront?.sellerProfile}
        products={products}
        handleSelectedProduct={handleSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <div
        className={
          products.length >= 2 ? 'w-full h-fit p-2' : 'w-full h-screen p-2'
        }
        style={{
          backgroundColor: storefront?.storefront?.style?.pageBackground,
        }}
      >
        <div
          className='mx-auto max-w-6xl h-12 mb-4 border rounded-md flex justify-between items-center drop-shadow-lg p-4'
          style={{
            backgroundColor: storefront?.storefront?.style?.cardBackground,
            borderColor: storefront?.storefront?.style?.borderColor,
          }}
        >
          <p className='font-medium text-sm'>My Templates</p>
          <p className='text-xs'>
            {products.length == 1
              ? `${products.length} template`
              : `${products.length} templates`}
          </p>
        </div>

        {products.length > 0 ? (
          <>
            <div>
              {currentProducts.map((product) => (
                <ProductCard
                  product={product}
                  storefront={storefront?.storefront}
                />
              ))}
            </div>
            {products.length > 3 ? (
              <div
                className='w-full'
                style={{
                  backgroundColor:
                    storefront?.storefront?.style?.pageBackground,
                }}
              >
                <div className='max-w-6xl flex justify-end mx-auto'>
                  <div
                    style={{
                      backgroundColor:
                        storefront?.storefront?.style?.pageBackground,
                    }}
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='Next'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel='Prev'
                      renderOnZeroPageCount={null}
                      className='flex items-center'
                      activeLinkClassName='activePage'
                      pageLinkClassName='notActivePage'
                      breakLinkClassName='breakLink'
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <div
            className='h-screen w-full flex items-center justify-center border rounded drop-shadow-md'
            style={{
              backgroundColor: storefront?.storefront?.style?.cardBackground,
              borderColor: storefront?.storefront?.style?.borderColor,
            }}
          >
            <p className='text-stone-800'>This store is empty</p>
          </div>
        )}
      </div>
      <Footer storefront={storefront?.storefront} />
    </>
  );
};

export default MobileHome;
