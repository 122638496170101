import React from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import Header from '../components/Header';
import Spinner from '../components/Spinner';
import Footer from '../components/Footer';
import { BsArrowRightShort } from 'react-icons/bs';
import moment from 'moment';
import DigitalSuccess from '../components/DigitalSuccess';
import { isMobile } from 'react-device-detect';
import MobileSuccess from '../components/Success/MobileSuccess';

const Success = ({ storefront, gettingStorefront, gotStorefront }) => {
  const { state } = useLocation(); //pulls out the state data(order) that came with the redirect from checkout page
  const { order } = state;

  let content;

  if (gettingStorefront) {
    content = <Spinner />;
  } else if (gotStorefront) {
    console.log(order);
    content = (
      <>
        {/* <Header storefront={storefront} /> */}
        {order?.item?.type === 'digital' ? (
          <DigitalSuccess order={order} storefront={storefront} />
        ) : isMobile ? (
          <MobileSuccess order={order} storefront={storefront} />
        ) : (
          <div
            className='w-full mx-auto h-screen'
            style={{
              backgroundColor: storefront?.storefront?.style?.pageBackground,
            }}
          >
            <div className='mx-auto max-w-7xl'>
              <div className='w-10/12 mx-auto flex justify-end'>
                <Link
                  to='/'
                  className='flex items-center text-lg font-medium'
                  style={{ color: storefront?.storefront?.style?.pageText }}
                >
                  Back to shopping
                  <BsArrowRightShort />
                </Link>
              </div>
              <div className='w-10/12 mx-auto h-40  flex flex-col'>
                <div
                  className='flex flex-col p-2 border-2 rounded'
                  style={{
                    borderColor: storefront?.storefront?.style?.borderColor,
                  }}
                >
                  <p
                    className='md:text-4xl text-3xl mb-4'
                    style={{ color: storefront?.storefront?.style?.pageText }}
                  >
                    Thanks for your order!
                  </p>
                  <p
                    className='md:text-xl'
                    style={{ color: storefront?.storefront?.style?.pageText }}
                  >
                    Order <span className='font-medium'>{order._id}</span> is in
                    the works! We sent an email to{' '}
                    <span className='font-medium'>{order.email}</span> with all
                    the order details.
                  </p>
                </div>

                <div
                  className='border-2 rounded mt-4 flex justify-between p-2'
                  style={{
                    borderColor: storefront?.storefront?.style?.borderColor,
                  }}
                >
                  <div className='w-6/12'>
                    <p
                      className='mb-2 font-medium md:text-xl'
                      style={{ color: storefront?.storefront?.style?.pageText }}
                    >
                      Estimated arrival:
                    </p>
                    {order?.item?.ali ? (
                      <p
                        className='md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        {moment
                          .utc(order?.item?.aliEstimatedDelivery)
                          .format('MMM D, YYYY')}
                      </p>
                    ) : (
                      <p
                        className='md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        Tracking link sent by email
                      </p>
                    )}
                  </div>
                  <div className='w-6/12 flex flex-col'>
                    <p
                      style={{ color: storefront?.storefront?.style?.pageText }}
                      className='mb-2 font-medium md:text-xl'
                    >
                      Shipping to:
                    </p>
                    <p
                      style={{ color: storefront?.storefront?.style?.pageText }}
                      className='md:text-xl'
                    >
                      {order?.firstName} {order?.lastName}
                    </p>
                    <p
                      style={{ color: storefront?.storefront?.style?.pageText }}
                      className='md:text-xl'
                    >
                      {order?.shippingAddress?.address}
                    </p>
                    <p
                      style={{ color: storefront?.storefront?.style?.pageText }}
                      className='md:text-xl'
                    >
                      {order?.shippingAddress?.city},{' '}
                      {order?.shippingAddress?.state}{' '}
                      {order?.shippingAddress?.zipcode} <br />
                      United States
                    </p>
                  </div>
                </div>

                <div
                  className='border-2 rounded mt-4 p-2'
                  style={{
                    borderColor: storefront?.storefront?.style?.borderColor,
                  }}
                >
                  <p
                    className='font-medium md:text-xl'
                    style={{ color: storefront?.storefront?.style?.pageText }}
                  >
                    Receipt:
                  </p>
                  <div className='flex justify-between pl-10 pr-10 mt-4'>
                    <div className='text-left'>
                      <p
                        className='md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        Item:
                      </p>
                      {Object.entries(order?.item?.options).length > 0 ? (
                        <p
                          className='md:text-xl mt-2'
                          style={{
                            color: storefront?.storefront?.style?.pageText,
                          }}
                        >
                          Options:
                        </p>
                      ) : (
                        ''
                      )}
                      <p
                        className='mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        Price:
                      </p>
                      <p
                        className='mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        Quantity:
                      </p>
                      <p
                        className='mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        Shipping & handling:
                      </p>
                      <p
                        className='font-medium mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        Total:
                      </p>
                    </div>

                    <div className='text-right'>
                      <p
                        className='md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        {order?.item?.title}
                      </p>
                      <div className='mt-2'>
                        {Object.entries(order.options).map(([key, value]) => (
                          <p>
                            {key}: {value}
                          </p>
                        ))}
                      </div>
                      <p
                        className='mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        ${order?.item?.price}
                      </p>
                      <p
                        className='mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        x {order?.qty}
                      </p>
                      <p
                        className='mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        ${order?.item?.shippingPrice.toFixed(2)}
                      </p>
                      <p
                        className='font-medium mt-2 md:text-xl'
                        style={{
                          color: storefront?.storefront?.style?.pageText,
                        }}
                      >
                        ${order?.total.toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer storefront={storefront.storefront} />
      </>
    );
  }

  return content;
};

export default Success;
