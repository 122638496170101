import { apiSlice } from './apiSlice';

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: ({ orderId }) => `/orders/${orderId}`,
    }),
    getOrderStatus: builder.query({
      query: ({ orderId }) => `/orders/status/${orderId}`,
    }),
    createOrder: builder.mutation({
      query: ({ total, item, qty, options }) => ({
        url: '/orders/create/',
        method: 'POST',
        body: {
          total: total,
          item: item,
          qty: qty,
          storeId: process.env.REACT_APP_STORE_ID,
          options: options,
        },
      }),
    }),
    updateOrderAmount: builder.mutation({
      query: ({ total, orderId }) => ({
        url: '/orders/updateorderamount/',
        method: 'POST',
        body: {
          total: total,
          orderId: orderId,
        },
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ orderId, email, total, country, zip, name }) => ({
        url: `/orders/update/${orderId}`,
        method: 'PUT',
        body: {
          email: email,
          country: country,
          zipcode: zip,
          name: name,
          total: total,
        },
      }),
    }),
  }),
});

export const {
  useGetOrderQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetOrderStatusQuery,
  useUpdateOrderAmountMutation,
} = orderApiSlice;
