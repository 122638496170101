import React, { useMemo } from 'react';
import {
  MdOutlineFileDownload,
  MdOutlineVideoLibrary,
  MdLocalPrintshop,
  MdOutlinePermMedia,
} from 'react-icons/md';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { BsFillMicFill } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import Select from 'react-select';
import countryList from 'react-select-country-list';

//stripe
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from '@stripe/react-stripe-js';

//mui
import Alert from '@mui/material/Alert';

const DigitalCheckoutMobile = ({
  error,
  storefront,
  order,
  setName,
  setZip,
  setCountry,
  setEmail,
  country,
  placingOrder,
  stripeOnboard,
  handleConfirmOrder,
  setReadyForPayment,
  handleSetReadyForPayment,
}) => {
  const options = useMemo(() => countryList().getData(), []);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        border: '1px solid #000', // Set the border
        borderRadius: '2px',
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleCountry = (value) => {
    console.log(value);
    setCountry(value);
  };

  const btnStyle =
    storefront?.style?.buttonStyle === 'filled'
      ? storefront?.style?.buttonColor
      : '';

  return (
    <div className='w-full'>
      <form onSubmit={handleConfirmOrder} className='flex flex-col'>
        {error && (
          <Alert severity='error' className='mt-4 mb-4 w-full'>
            {error}
          </Alert>
        )}
        <div className='w-full'>
          <div className='mb-2'>
            <p className='text-sm'>Item</p>
            <div
              className='flex border-2 rounded-md mt-1'
              style={{ borderColor: storefront?.style?.borderColor }}
            >
              <div className='w-10/12 flex justify-center items-center p-1'>
                <p className='text-md text-stone-800'>{order?.item?.title}</p>
              </div>
              <div
                className='w-2/12 flex items-center justify-center border-l-2 p-1 rounded-tr-md rounded-br-md'
                style={{
                  borderColor: storefront?.style?.borderColor,
                  backgroundColor: storefront?.style?.price,
                }}
              >
                <p className='text-md'>${order?.total}</p>
              </div>
            </div>
            <p
              className='mt-2 text-sm'
              style={{ color: storefront?.style?.textColor }}
            >
              Deliver to
            </p>
            <input
              type='email'
              placeholder='Email'
              className='border-2 w-full rounded-md p-2 outline outline-0 h-10 drop-shadow-md text-sm mt-1'
              onChange={(e) => setEmail(e.target.value)}
              style={{ borderColor: storefront?.style?.borderColor }}
            />

            <input
              type='text'
              placeholder='Name'
              className='border-2 w-full border-slate-200 hover:border-slate-300 rounded-md p-2 mt-2 outline outline-0 h-10 drop-shadow-md text-sm'
              onChange={(e) => setName(e.target.value)}
              style={{ borderColor: storefront?.style?.borderColor }}
            />
            <div className='flex items-center mt-2'>
              <Select
                options={options}
                value={country}
                onChange={handleCountry}
                className='w-6/12 h-10 shadow-md text-sm'
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: storefront?.style?.borderColor,
                    '&:hover': {
                      borderColor: storefront?.style?.borderColor, // Keep the same border color on hover
                    },
                    boxShadow: 'none',
                    borderWidth: 2,
                    zIndex: 99999,
                    position: 'relative',
                    borderRadius: 6,
                  }),
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
              <input
                type='text'
                placeholder='ZIP'
                className='border-2 w-6/12 rounded-md p-2 outline outline-0 h-10 ml-2 text-sm drop-shadow-md'
                onChange={(e) => setZip(e.target.value)}
                pattern='^\d{5}$'
                title='Please enter a 5-digit zip code'
                style={{ borderColor: storefront?.style?.borderColor }}
              />
            </div>
          </div>
        </div>

        <p className='mt-1 text-sm'>Payment</p>
        <div
          className='border-2 rounded-md h-10 p-2 mt-1 shadow-md'
          style={{ borderColor: storefront?.style?.borderColor }}
        >
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>

        {/* <PaymentElement /> */}

        <div className='w-full flex items-center'>
          <button
            type='button'
            onClick={handleSetReadyForPayment}
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-3/12 h-10 text-lg border-2 rounded-md mt-4 mr-2 shadow-md flex items-center justify-center'
          >
            <FaChevronLeft className='text-sm' />
          </button>
          <button
            disabled={placingOrder || !stripeOnboard}
            type='submit'
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-9/12 h-10 text-sm border-2 rounded-md mt-4 shadow-md'
          >
            {placingOrder
              ? 'Placing order...'
              : order?.item?.callToAction === 'buy'
              ? 'Buy Now'
              : order?.item?.callToAction === 'want'
              ? 'I want this!'
              : 'Get Now'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DigitalCheckoutMobile;
