import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Checkout from './Checkout';
import ProductDesc from '../components/ProductDesc';
import Reviews from '../components/Reviews';
import { useCreateOrderMutation } from '../api/orderApiSlice';
import {
  MdOutlineVideoLibrary,
  MdLocalPrintshop,
  MdOutlinePermMedia,
} from 'react-icons/md';
import { HiOutlineBookOpen, HiOutlineTemplate } from 'react-icons/hi';
import { BsFillMicFill, BsPalette } from 'react-icons/bs';
import { FaRegSadTear } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { Carousel } from 'flowbite-react';
import { Helmet } from 'react-helmet';

//mui
import Alert from '@mui/material/Alert';
import Rating from '@mui/material/Rating';

const DetailDesktop = ({ storefront, products, error, setError }) => {
  const { productUrl } = useParams();
  const navigate = useNavigate();
  const orderId = Cookies.get('orderId') ? Cookies.get('orderId') : null;

  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [priceChoice, setPriceChoice] = useState(0);
  const visited = localStorage.getItem('visited')
    ? localStorage.getItem('visited')
    : null;

  const btnStyle =
    storefront?.storefront?.style?.buttonStyle === 'filled'
      ? storefront?.storefront?.style?.buttonColor
      : '';

  const [createOrder, { isSuccess: createdOrder }] = useCreateOrderMutation();

  const btnDisabled = selectedProduct?.item?.free
    ? false
    : storefront?.stripeOnboard
    ? false
    : true;

  const handleSetReadyForPayment = () => {
    setReadyForPayment(false);
    setPriceChoice(0);
    Cookies.remove('orderId');
  };

  const handleSelectedProduct = (e) => {
    const productId = e.value;
    const selected = products.find((product) => product.item._id === productId);

    setSelectedProduct(selected);

    setError('');

    navigate(`/${selected?.item?.url}`);
  };

  const handleMoveToCheckout = async (e) => {
    e.preventDefault();

    console.log(orderId);

    if (
      selectedProduct?.item?.payChoice &&
      priceChoice < selectedProduct?.item?.price
    ) {
      setError(`Price has to be $${selectedProduct?.item?.price} or more!`);
      return;
    }

    if (orderId) {
      setReadyForPayment(true);
      // navigate(`/checkout/${orderId}`);
    } else {
      try {
        const createOrderReq = await createOrder({
          total: selectedProduct?.item?.payChoice
            ? priceChoice
            : selectedProduct?.item?.price,
          item: selectedProduct?.item,
        }).unwrap();
        Cookies.set('orderId', createOrderReq.orderId);
        // navigate(`/checkout/${createOrderReq.orderId}`);
        setReadyForPayment(true);
      } catch (err) {
        setError('There was a server error');
      }
    }
  };

  useEffect(() => {
    const setInitialProduct = (e) => {
      const selected = products.find(
        (product) => product.item.url === productUrl
      );

      console.log(selected);

      setSelectedProduct(selected);
    };

    if (productUrl) setInitialProduct();
  }, []);

  useEffect(() => {
    setError('');
  }, [priceChoice]);

  useEffect(() => {
    setError('');
    setPriceChoice(0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name='twitter:image'
          content={selectedProduct?.item?.coverImages[0]?.url}
        />
      </Helmet>
      <Header
        storefront={storefront?.storefront}
        sellerProfile={storefront?.sellerProfile}
        storeIds={storefront?.storeIds}
        products={products}
        handleSelectedProduct={handleSelectedProduct}
        selectedProduct={selectedProduct}
      />

      {products.length > 0 ? (
        <div
          className='w-full pt-10'
          style={{
            backgroundColor: storefront?.storefront?.style?.pageBackground,
          }}
        >
          <div
            className='flex flex-col max-w-6xl mx-auto h-fit p-4 border rounded-md drop-shadow-lg bg-inherit'
            style={{
              backgroundColor: storefront?.storefront?.style?.cardBackground,
              borderColor: storefront?.storefront?.style?.borderColor,
            }}
          >
            <div className='w-full flex justify-between mx-auto mt-4'>
              <div
                className='w-3/6 border rounded-md flex items-center justify-center h-80'
                style={{
                  borderColor: storefront?.storefront?.style?.borderColor,
                }}
              >
                {selectedProduct?.item?.coverImages.length ? (
                  <Carousel className='w-full' arrowSize={10}>
                    {selectedProduct?.item?.coverImages.map((img) => (
                      <img
                        src={img?.url}
                        className='rounded-md object-fill h-full w-full'
                      />
                    ))}
                  </Carousel>
                ) : (
                  <img
                    src={selectedProduct?.item?.coverImage?.url}
                    className='rounded object-fill h-full w-full'
                  />
                )}
              </div>
              {readyForPayment ? (
                <Checkout
                  orderId={orderId}
                  storefront={storefront}
                  setReadyForPayment={setReadyForPayment}
                  handleSetReadyForPayment={handleSetReadyForPayment}
                />
              ) : (
                <>
                  <div className='w-3/6 flex flex-col md:pl-10'>
                    {error ? (
                      <Alert severity='error' className='w-full'>
                        {error}
                      </Alert>
                    ) : (
                      ''
                    )}
                    <p
                      className='text-xl font-bold mt-4 md:mt-0'
                      style={{ color: storefront?.storefront?.style?.pageText }}
                    >
                      {selectedProduct?.item?.title}
                    </p>
                    <p
                      className='text-md mt-2 mb-2'
                      style={{ color: storefront?.storefront?.style?.pageText }}
                    >
                      {selectedProduct?.item?.description}
                    </p>
                    <form>
                      <div className='flex items-center mt-4'>
                        <div
                          className='flex justify-between items-center p-2 rounded-md border'
                          style={{
                            borderColor: storefront?.storefront?.style?.price,
                            backgroundColor:
                              storefront?.storefront?.style?.price,
                          }}
                        >
                          <p
                            className='text-xl font-medium'
                            style={{
                              color: storefront?.storefront?.style?.pageText,
                            }}
                          >
                            {selectedProduct?.item?.free
                              ? 'FREE'
                              : selectedProduct?.item?.payChoice
                              ? `$ ${selectedProduct?.item?.price?.toLocaleString(
                                  'en-US',
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }
                                )} +`
                              : `$${selectedProduct?.item?.price?.toLocaleString(
                                  'en-US',
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }
                                )}`}
                          </p>
                        </div>

                        <div className='flex items-center ml-6'>
                          <Rating
                            value={selectedProduct?.totalRating}
                            readOnly
                            precision={0.5}
                          />
                          <a
                            href='#reviews'
                            style={{
                              color: storefront?.storefront?.style?.pageText,
                            }}
                          >
                            <p className='ml-2'>
                              {selectedProduct?.reviews?.length
                                ? `(${selectedProduct?.reviews?.length}) reviews`
                                : '(0) reviews'}
                            </p>
                          </a>
                        </div>
                      </div>

                      <div className='w-full flex justify-between items-center mt-4'>
                        {selectedProduct?.item?.payChoice ? (
                          <div className='flex flex-col w-full'>
                            <p className='text-sm font-medium'>
                              Set your price
                            </p>
                            <div className='w-full flex mt-1'>
                              <div className='flex items-center w-3/12'>
                                <div className=''>
                                  <p className='font-medium mr-1 text-2xl'>$</p>
                                </div>
                                <input
                                  type='number'
                                  className='border-2 mr-2 rounded-md h-10 bg-transparent p-2 outline outline-0 w-full shadow-lg'
                                  style={{
                                    borderColor:
                                      storefront?.storefront?.style
                                        ?.borderColor,
                                    WebkitAppearance: 'none',
                                    MozAppearance: 'textfield',
                                  }}
                                  placeholder={`$${selectedProduct?.item?.suggestedPrice} +`}
                                  min={selectedProduct?.item?.price}
                                  onChange={(e) =>
                                    setPriceChoice(e.target.value)
                                  }
                                />
                              </div>
                              <button
                                disabled={btnDisabled}
                                onClick={handleMoveToCheckout}
                                style={{
                                  color:
                                    storefront?.storefront?.style
                                      ?.buttonTextColor,
                                  backgroundColor: btnStyle,
                                  borderColor:
                                    storefront?.storefront?.style?.buttonColor,
                                }}
                                className='w-9/12 h-10 text-md border-2 rounded-md shadow-lg'
                              >
                                {selectedProduct?.item?.callToAction === 'buy'
                                  ? 'Buy Now'
                                  : selectedProduct?.item?.callToAction ===
                                    'want'
                                  ? 'I want this!'
                                  : 'Get Now'}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <button
                            disabled={btnDisabled}
                            onClick={handleMoveToCheckout}
                            style={{
                              color:
                                storefront?.storefront?.style?.buttonTextColor,
                              backgroundColor: btnStyle,
                              borderColor:
                                storefront?.storefront?.style?.buttonColor,
                            }}
                            className='w-full h-10 text-md border-2 rounded-md shadow-lg'
                          >
                            {selectedProduct?.item?.callToAction === 'buy'
                              ? 'Buy Now'
                              : selectedProduct?.item?.callToAction === 'want'
                              ? 'I want this!'
                              : 'Get Now'}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>

            {storefront?.storefront?.hideDescription ? (
              ''
            ) : (
              <ProductDesc
                product={selectedProduct?.item}
                storefront={storefront.storefront}
              />
            )}

            {/* {storefront?.storefront?.hideQuestions ? (
              ''
            ) : (
              <FAQs
                faqs={selectedProduct?.item?.faqs}
                storefront={storefront.storefront}
              />
            )} */}

            {storefront?.storefront?.hideReviews ? (
              ''
            ) : (
              <Reviews
                storefront={storefront.storefront}
                reviews={selectedProduct?.reviews}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className='h-screen mx-auto p-4'
          style={{
            backgroundColor: storefront?.storefront?.style?.pageBackground,
          }}
        >
          <div
            className='w-11/12 max-w-6xl h-5/6 flex flex-col justify-center items-center border rounded bg-inherit mx-auto drop-shadow-lg'
            style={{
              borderColor: storefront?.storefront?.style?.borderColor,
              backgroundColor: storefront?.storefront?.style?.cardBackground,
            }}
          >
            <p
              className='text-xl text-center'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              This product page is not offering anything at the moment!
            </p>
            <FaRegSadTear className='text-4xl mt-4' />
            <p
              className='text-lg'
              style={{ color: storefront?.storefront?.style?.pageText }}
            ></p>
          </div>
        </div>
      )}
      <Footer storefront={storefront.storefront} />
    </>
  );
};

export default DetailDesktop;
