import React from 'react';
import {
  MdOutlineFileDownload,
  MdOutlineVideoLibrary,
  MdLocalPrintshop,
  MdOutlinePermMedia,
} from 'react-icons/md';
import { HiOutlineBookOpen, HiOutlineTemplate } from 'react-icons/hi';
import { BsFillMicFill, BsPalette } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';

//mui
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';

const ProductCard = ({ product, storefront }) => {
  return isMobile ? (
    <Link to={`/${product?.item?.url}`}>
      <div
        className='border drop-shadow-lg flex flex-col mb-4 rounded-md relative p-4'
        style={{
          backgroundColor: storefront?.style?.cardBackground,
          borderColor: storefront?.style?.borderColor,
        }}
      >
        <div
          className='absolute bottom-0 right-0 mr-1 mb-1 rounded-tl p-2'
          style={{
            backgroundColor: storefront?.style?.price,
          }}
        >
          <p
            className={
              product?.item?.free
                ? 'font-medium text-lg'
                : 'font-medium text-lg'
            }
          >
            {product?.item?.free
              ? 'FREE'
              : product?.item?.payChoice
              ? `$ ${product?.item?.price?.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} +`
              : `$${product?.item?.price?.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
          </p>
        </div>

        <div className='absolute bottom-0 mt-4'>
          <Rating
            value={product?.totalRating}
            readOnly
            precision={0.5}
            size='medium'
          />
        </div>

        <div className='w-full h-44 mb-2'>
          <img
            className='object-cover w-full h-full rounded'
            src={
              product?.item?.coverImages.length
                ? product?.item?.coverImages[0]?.url
                : product?.item?.coverImage?.url
            }
          />
        </div>

        <p className='font-bold text-lg mt-2'>{product?.item?.title}</p>
        <p className='font-medium text-md mt-2 mb-10'>
          {product?.item?.description}
        </p>
      </div>
    </Link>
  ) : (
    <Link to={`/${product?.item?.url}`} className='w-full'>
      <div
        className='border drop-shadow-lg flex w-full mb-4 rounded-md relative'
        style={{
          backgroundColor: storefront?.style?.cardBackground,
          borderColor: storefront?.style?.borderColor,
          height: '350px',
        }}
      >
        <div
          className='absolute bottom-0 right-0 mr-1 mb-1 rounded-br-md p-2'
          style={{
            backgroundColor: storefront?.style?.price,
          }}
        >
          <p
            className={
              product?.item?.free
                ? 'font-medium text-lg'
                : 'font-medium text-lg'
            }
          >
            {product?.item?.free
              ? 'FREE'
              : product?.item?.payChoice
              ? `$ ${product?.item?.price?.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} +`
              : `$${product?.item?.price?.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
          </p>
        </div>

        <div className='w-full flex-col p-4'>
          <div className='w-full h-44 pb-2'>
            <img
              src={
                product?.item?.coverImages.length
                  ? product?.item?.coverImages[0]?.url
                  : product?.item?.coverImage?.url
              }
              className='object-fill w-full h-full rounded'
            />
          </div>
          {/* <div className='mb-2 mt-1'>
            {product?.item?.digitalType === 'video' ? (
              <div className='flex items-center justify-center border-2 border-slate-800 rounded w-40 h-8'>
                <p className='text-sm'>Video Course</p>
                <MdOutlineVideoLibrary className='ml-2 text-xl' />
              </div>
            ) : product?.item?.digitalType === 'ebook' ? (
              <div className='flex items-center justify-center border-2 border-slate-800 rounded w-40 h-8'>
                <p className='text-sm'>E-Book</p>
                <HiOutlineBookOpen className='ml-2 text-xl' />
              </div>
            ) : product?.item?.digitalType === 'audio' ? (
              <div className='flex items-center justify-center border-2 border-slate-800 rounded w-40 h-8'>
                <p className='text-sm'>Audio</p>
                <BsFillMicFill className='ml-2 text-xl' />
              </div>
            ) : product?.item?.digitalType === 'other' ? (
              <div className='flex items-center justify-center border-2 border-slate-800 rounded w-40 h-8'>
                <p className='text-sm'> Digital Media</p>
                <MdOutlinePermMedia className='ml-2 text-xl' />
              </div>
            ) : product?.item?.digitalType === 'template' ? (
              <div className='flex items-center justify-center border-2 border-stone-800 rounded w-40 h-8 mt-2'>
                <p className='text-sm'>Template</p>
                <HiOutlineTemplate className='ml-2 text-xl' />
              </div>
            ) : (
              <div className='flex items-center justify-center border-2 border-slate-800  rounded w-40 h-8'>
                <p className='text-sm'>Art</p>
                <BsPalette className='ml-2 text-xl' />
              </div>
            )}
          </div> */}

          <p className='font-bold text-lg mb-1'>{product?.item?.title}</p>
          <p className='text-md mb-2'>{product?.item?.description}</p>
        </div>

        <div className='absolute bottom-0 ml-2'>
          <Rating
            value={product?.totalRating}
            readOnly
            precision={0.5}
            size='medium'
          />
        </div>

        {/* <div className='w-3/12 flex items-center justify-center p-4'>
          <img className='rounded' src={product?.item?.coverImage?.url} />
        </div> */}
      </div>
    </Link>
  );
};

export default ProductCard;
