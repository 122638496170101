import React from 'react';

const ReviewSuccess = ({ storefront }) => {
  return (
    <div
      className='w-full mx-auto h-screen flex flex-col items-center'
      style={{ backgroundColor: storefront?.style?.pageBackground }}
    >
      <div
        className='border-2 h-5/6 w-10/12 flex justify-center items-center'
        style={{ borderColor: storefront?.style?.borderColor }}
      >
        <p
          className='text-center'
          style={{ color: storefront?.style?.pageText }}
        >
          Thank you for the review!
        </p>
      </div>
    </div>
  );
};

export default ReviewSuccess;
