import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetStoreItemQuery } from '../api/itemApiSlice';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Spinner from '../components/Spinner';
import Cookies from 'js-cookie';
import { useAddVisitMutation } from '../api/storefrontApiSlice';
import { isMobile } from 'react-device-detect';
import DetailDesktop from './DetailDesktop';
import DetailMobile from './DetailMobile';
import { Helmet } from 'react-helmet';

const Detail = ({
  storefront,
  gettingStorefront,
  gotStorefront,
  stripeOnboard,
}) => {
  const [error, setError] = useState('');
  const [priceChoice, setPriceChoice] = useState(0);

  const orderId = Cookies.get('orderId') ? Cookies.get('orderId') : null;

  //gets item for storefront
  const {
    data: productData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetStoreItemQuery();

  useEffect(() => {
    setError('');
  }, [priceChoice]);

  useEffect(() => {
    refetch();
  }, []);

  let content;

  if (isLoading || gettingStorefront) {
    content = <Spinner />;
  } else if (isSuccess && gotStorefront) {
    content = isMobile ? (
      <DetailMobile
        storefront={storefront}
        products={productData.products}
        error={error}
        setError={setError}
        orderId={orderId}
        setPriceChoice={setPriceChoice}
      />
    ) : (
      <DetailDesktop
        storefront={storefront}
        error={error}
        setError={setError}
        orderId={orderId}
        setPriceChoice={setPriceChoice}
        products={productData?.products}
      />
    );
  }

  return content;
};

export default Detail;
