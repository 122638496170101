import { apiSlice } from './apiSlice';

export const storefrontApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStorefront: builder.query({
      query: () => `/storefront/get/${process.env.REACT_APP_STORE_ID}`,
    }),
    addVisit: builder.mutation({
      query: () => ({
        url: `/storefront/addvisit/`,
        method: 'POST',
        body: {
          storeId: process.env.REACT_APP_STORE_ID,
        },
      }),
    }),
  }),
});

export const {
  useGetStorefrontQuery,
  useAddVisitMutation,
} = storefrontApiSlice;
