import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useGetStoreItemQuery } from '../api/itemApiSlice';
import MobileHome from '../components/Home/MobileHome';
import DesktopHome from '../components/Home/DesktopHome';
import Spinner from '../components/Spinner';
import { useAddVisitMutation } from '../api/storefrontApiSlice';
import Cookies from 'js-cookie';

const Home = ({ storefront, gettingStorefront, gotStorefront }) => {
  const visited = Cookies.get('visited') ? Cookies.get('visited') : null;

  //gets item for storefront
  const {
    data: productData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetStoreItemQuery();

  const [addVisit, { isSuccess: visitAdded }] = useAddVisitMutation();

  useEffect(() => {
    const markVisited = async () => {
      const addVisitReq = await addVisit().unwrap();
      if (addVisitReq === 'Visit tracked')
        localStorage.setItem('visited', true);
      Cookies.set('visited', true);
    };

    if (!visited) markVisited();
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  let content;

  if (isLoading || gettingStorefront) {
    content = <Spinner />;
  } else if (isSuccess && gotStorefront) {
    content = isMobile ? (
      <MobileHome products={productData?.products} storefront={storefront} />
    ) : (
      <DesktopHome products={productData?.products} storefront={storefront} />
    );
  }
  return content;
};

export default Home;
