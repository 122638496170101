import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import { useGetOrderStatusQuery } from '../api/orderApiSlice';

const Tracking = ({ storefront, gettingStorefront, gotStorefront }) => {
  const { orderId } = useParams();

  const { data: orderStatus, isLoading, isSuccess } = useGetOrderStatusQuery({
    orderId,
  });

  let content;

  if (gettingStorefront) {
    content = <Spinner />;
  } else if (gotStorefront && isSuccess) {
    console.log(orderStatus);
    content = (
      <>
        <Header storefront={storefront} />
        <div
          className='w-full mx-auto h-screen flex flex-col items-center'
          style={{ backgroundColor: storefront?.style?.pageBackground }}
        >
          <div>Track order here</div>
        </div>
        <Footer storefront={storefront} />
      </>
    );
  }

  return content;
};

export default Tracking;
