import React from 'react';
import { Link } from 'react-router-dom';
import { IoStorefrontOutline } from 'react-icons/io5';

const Footer = ({ storefront }) => {
  return (
    <footer
      className='h-32 w-full flex flex-col justify-end'
      style={{
        backgroundColor: storefront?.style?.pageBackground,
        display: storefront?.style?.hideFooter ? 'none' : '',
      }}
    >
      <div className='max-w-6xl mx-auto h-8 mb-4'>
        <div className='flex items-center justify-center bg-white p-2 rounded h-8 w-48 mx-auto'>
          <a
            href='https://fruntt.com'
            target='_blank'
            className='h-full flex justify-center items-center'
          >
            <p className='text-md'>Powered by</p>
            <IoStorefrontOutline className='text-slate-800 font-bold ml-2' />
            <p className='font-black text-slate-800 font-sans'>Fruntt</p>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
