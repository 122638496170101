import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateOrderMutation,
  useUpdateOrderAmountMutation,
} from '../api/orderApiSlice';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import DigitalCheckoutMobile from '../components/DigitalCheckout/DigitalCheckoutMobile';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import Select from 'react-select';
import { countryOptions } from '../countryOptions';
import countryList from 'react-select-country-list';

//stripe
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from '@stripe/react-stripe-js';

//mui
import Alert from '@mui/material/Alert';

const CheckoutForm = ({
  order,
  storefront,
  stripeOnboard,
  setReadyForPayment,
  handleSetReadyForPayment,
  refetch,
}) => {
  const navigate = useNavigate();

  //form state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [zip, setZip] = useState('');
  const [error, setError] = useState('');
  const [placingOrder, setPlacingOrder] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const stripe = useStripe(); //for submitting payments to stripe
  const elements = useElements(); //for submitting payments to stripe

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        border: '2px solid #000', // Set the border
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleCountry = (value) => {
    setCountry(value);
  };

  const btnStyle =
    storefront?.style?.buttonStyle === 'filled'
      ? storefront?.style?.buttonColor
      : '';

  // const [updateOrderAmount, result] = useUpdateOrderAmountMutation();
  const [updateOrder, { isLoading }] = useUpdateOrderMutation();

  const handleConfirmOrder = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    //make sure all fields are filled out
    if (!email || !country || !name) {
      setError('All feilds must be filled out');
      return;
    }

    try {
      setPlacingOrder(true);

      const updatedOrderReq = await updateOrder({
        orderId: order._id,
        email: email.toLowerCase(),
        country,
        zip,
        name,
      }).unwrap();

      //update the order on the server
      if (updatedOrderReq.msg === 'Order updated') {
        const result = await stripe.confirmCardPayment(order?.clientId, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });
        if (result.error) {
          setError(result.error.message);
          setPlacingOrder(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            setPlacingOrder(false);
            Cookies.remove('orderId');
            navigate(`/order/success/`, {
              state: { order: updatedOrderReq.order },
            });
          }
        }
      } else {
        setPlacingOrder(false);
        setError('There was an error');
        return;
      }
    } catch (err) {
      setPlacingOrder(false);
      setError('There was an error');
      return;
    }
  };

  useEffect(() => {
    setError('');
  }, [zip, name, email]);

  return isMobile ? (
    <DigitalCheckoutMobile
      error={error}
      storefront={storefront}
      order={order}
      setName={setName}
      setEmail={setEmail}
      setZip={setZip}
      setCountry={setCountry}
      placingOrder={placingOrder}
      handleConfirmOrder={handleConfirmOrder}
      stripeOnboard={stripeOnboard}
      handleSetReadyForPayment={handleSetReadyForPayment}
    />
  ) : (
    <div>
      <form onSubmit={handleConfirmOrder} className='flex flex-col'>
        {error && (
          <Alert severity='error' className='w-full'>
            {error}
          </Alert>
        )}
        <div className='w-full'>
          <div className='mb-2 flex flex-col'>
            <p className='text-md'>Item</p>
            <div
              className='flex border-2 rounded-md h-10 mt-1 shadow-md'
              style={{ borderColor: storefront?.style?.borderColor }}
            >
              <div className='w-10/12 flex justify-center items-center'>
                <p className='text-md text-stone-800'>{order?.item?.title}</p>
              </div>
              <div
                className='w-2/12 flex items-center justify-center border-l-2 rounded-tr-md rounded-br-md'
                style={{
                  borderColor: storefront?.style?.borderColor,
                  backgroundColor: storefront?.style?.price,
                }}
              >
                <p className='text-md'>${order?.total}</p>
              </div>
            </div>
            <p className='mt-1 text-md'>Deliver to</p>
            <input
              type='email'
              placeholder='Email'
              className='border-2 w-full mt-1 drop-shadow-md rounded-md p-2 outline outline-0 h-10'
              onChange={(e) => setEmail(e.target.value)}
              style={{ borderColor: storefront?.style?.borderColor }}
            />

            <input
              type='text'
              placeholder='Name'
              className='border-2 w-full drop-shadow-md rounded-md p-2 mt-2 outline outline-0 h-10'
              onChange={(e) => setName(e.target.value)}
              style={{ borderColor: storefront?.style?.borderColor }}
            />
            <div className='flex items-center mt-2'>
              <Select
                options={options}
                value={country}
                onChange={handleCountry}
                className='w-6/12 h-10 shadow-md rounded-md'
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: storefront?.style?.borderColor,
                    '&:hover': {
                      borderColor: storefront?.style?.borderColor, // Keep the same border color on hover
                    },
                    boxShadow: 'none',
                    borderWidth: 2,
                    borderRadius: 6,
                    zIndex: 99999,
                    position: 'relative',
                  }),
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
              <input
                type='text'
                placeholder='ZIP'
                className='border-2 w-6/12 shadow-md rounded-md p-2 outline outline-0 h-10 ml-2'
                onChange={(e) => setZip(e.target.value)}
                pattern='^\d{5}$'
                title='Please enter a 5-digit zip code'
                style={{ borderColor: storefront?.style?.borderColor }}
              />
            </div>
          </div>
        </div>

        <p className='mt-1 text-md'>Payment</p>
        <div
          className='border-2 rounded-md h-10 p-2 mt-1 shadow-md'
          style={{ borderColor: storefront?.style?.borderColor }}
        >
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>

        {/* <PaymentElement /> */}

        <div className='w-full flex items-center mt-2'>
          <button
            type='button'
            disabled={placingOrder}
            onClick={handleSetReadyForPayment}
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-3/12 h-10 text-lg border-2 shadow-md rounded-md mt-2 mr-2 flex items-center justify-center'
          >
            <FaChevronLeft className='text-xl' />
          </button>
          <button
            disabled={placingOrder || !stripeOnboard}
            type='submit'
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-9/12 h-10 text-lg border-2 rounded-md shadow-md mt-2'
          >
            {placingOrder
              ? 'Placing order...'
              : order?.item?.callToAction === 'buy'
              ? 'Buy Now'
              : order?.item?.callToAction === 'want'
              ? 'I want this!'
              : 'Get Now'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
