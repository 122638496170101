import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import {
  getSingleCustomerQeury,
  useGetCustomerQuery,
  useSubmitReviewMutation,
} from '../api/itemApiSlice';

//mui
import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';

const Review = ({ storefront, gettingStorefront, gotStorefront }) => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [error, setError] = useState('');

  const {
    data: customerAndOrder,
    isLoading: gettingCustomerAndOrder,
    isSuccess: gotCustomerAndOrder,
  } = useGetCustomerQuery(customerId);

  const [
    submitReview,
    { isLoading: submittingReview },
  ] = useSubmitReviewMutation();

  const handleSubmitReview = async (e) => {
    e.preventDefault();

    try {
      const submitReviewReq = await submitReview({
        customerId: customerId,
        content: review,
        rating: rating,
      }).unwrap();
      console.log(submitReviewReq);
      if (submitReviewReq === 'Review added') {
        navigate('/order/review/success');
      }
    } catch (err) {
      setError('There was an error submitting the review, try again later');
      return;
    }
  };

  let content;

  if (gettingStorefront || gettingCustomerAndOrder) {
    content = <Spinner />;
  } else if (gotStorefront && gotCustomerAndOrder) {
    const btnStyle =
      storefront?.style?.buttonStyle === 'filled'
        ? storefront?.style?.buttonColor
        : '';

    content = (
      <>
        <Header storefront={storefront} />
        <div
          className='w-full mx-auto h-screen pt-20'
          style={{ backgroundColor: storefront?.style?.pageBackground }}
        >
          <div
            className='mx-auto max-w-6xl border-2 rounded p-4'
            style={{ backgroundColor: storefront?.style?.pageBackground }}
          >
            {error && (
              <Alert severity='error' className='mt-4 mb-4 w-full'>
                {error}
              </Alert>
            )}
            <div className='w-full'>
              <p className='text-2xl'>
                Hello {customerAndOrder.customer?.firstName}!
              </p>
              <p className='text-2xl mt-2'>
                Recently you made a purchase from us and we would love to hear
                back from you! If you could just take a minute and leave a
                review below we would really appreaciate that.
              </p>
            </div>
            <form
              className='flex flex-col mt-4'
              onSubmit={handleSubmitReview}
              style={{ backgroundColor: storefront?.style?.pageBackground }}
            >
              <p className='mt-2 text-lg font-medium'>Your order was</p>
              <div className='mx-auto w-8/12 flex justify-between'>
                <div className='w-3/6 flex flex-col text-left'>
                  <p
                    className='text-lg'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    Item:
                  </p>
                  <p
                    className='text-lg mt-2'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    Price:
                  </p>
                  <p
                    className='text-lg mt-2 border-b w-full'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    Qty:
                  </p>
                  <p
                    className='text-lg font-medium mt-2'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    Total:
                  </p>
                </div>
                <div className='w-3/6 flex flex-col text-right'>
                  <p
                    className='text-lg'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    {customerAndOrder.order?.item?.title}
                  </p>
                  <p
                    className='text-lg mt-2'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    ${customerAndOrder.order?.item?.price}
                  </p>
                  <p
                    className='text-lg mt-2 border-b'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    x{customerAndOrder.order?.qty}
                  </p>
                  <p
                    className='text-lg font-medium mt-2'
                    style={{ color: storefront?.style?.pageText }}
                  >
                    ${customerAndOrder.order?.total}
                  </p>
                </div>
              </div>
              <p
                className='mt-2 text-lg font-medium'
                style={{ color: storefront?.style?.pageText }}
              >
                Rating
              </p>
              <Rating
                onChange={(e) => setRating(e.target.value)}
                precision={0.5}
                className='mt-2'
                size='large'
              />
              <p
                className='mt-2 text-lg font-medium'
                style={{ color: storefront?.style?.pageText }}
              >
                Review
              </p>
              <textarea
                onChange={(e) => setReview(e.target.value)}
                className='border-2 border-slate-200 hover:border-slate-300 w-full rounded-lg p-2 outline outline-0 bg-white mt-2'
                placeholder='Enter review here'
              />
              <button
                type='submit'
                style={{
                  color: storefront?.style?.buttonTextColor,
                  backgroundColor: btnStyle,
                  borderColor: storefront?.style?.buttonColor,
                }}
                className='w-full h-14 text-2xl border-2 border-slate-800 rounded mt-4'
              >
                Submit Review
              </button>
            </form>
          </div>
        </div>
        <Footer storefront={storefront} />
      </>
    );
  }

  return content;
};

export default Review;
