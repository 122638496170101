import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Spinner from '../components/Spinner';
import CheckoutForm from '../components/CheckoutForm';
import { useGetOrderQuery } from '../api/orderApiSlice';
import { useParams, Link } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';

//stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Footer from '../components/Footer';
import NoPayCheckoutForm from '../components/NoPayCheckoutForm';

const Checkout = ({
  storefront,
  orderId,
  setReadyForPayment,
  handleSetReadyForPayment,
}) => {
  const navigate = useNavigate();

  // const { orderId } = useParams();
  const {
    data: order,
    isLoading,
    isSuccess,
    refetch,
  } = useGetOrderQuery({
    orderId,
  });

  const stripeLoader = loadStripe('pk_live_eWcmPkgRoGeR0yn9gKf7wopL00xb4WlY4M');
  const stripeOptions = {
    clientSecret: order?.clientId,
    appearance: {
      theme: 'stripe',

      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: storefront?.storefront?.style?.pageText,
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        fontSizeBase: '14px',
        borderRadius: '10px',
        borderColor: storefront?.storefront?.style?.borderColor,
        border: `2px solid ${storefront?.storefront?.style?.borderColor}`,
      },
    },
  };

  let content;
  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    if (order.paid === true)
      navigate(`/order/success/`, { state: { order: order } });

    content = isMobile ? (
      <div
        className='w-full'
        style={{
          backgroundColor: storefront?.storefront?.style?.cardBackground,
        }}
      >
        <div
          className='mx-auto mt-4'
          style={{
            backgroundColor: storefront?.storefront?.style?.cardBackground,
          }}
        >
          <div
            style={{
              backgroundColor: storefront?.storefront?.style?.cardBackground,
            }}
          >
            {order?.total == 0 ? (
              <NoPayCheckoutForm
                order={order}
                storefront={storefront?.storefront}
                stripeOnboard={storefront?.stripeOnboard}
                setReadyForPayment={setReadyForPayment}
                handleSetReadyForPayment={handleSetReadyForPayment}
              />
            ) : (
              <Elements stripe={stripeLoader} options={stripeOptions}>
                <CheckoutForm
                  order={order}
                  storefront={storefront.storefront}
                  stripeOnboard={storefront.stripeOnboard}
                  setReadyForPayment={setReadyForPayment}
                  refetch={refetch}
                  handleSetReadyForPayment={handleSetReadyForPayment}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div
        className='w-3/6'
        style={{
          backgroundColor: storefront?.storefront?.style?.cardBackground,
        }}
      >
        <div
          className='mx-auto md:ml-4'
          style={{
            backgroundColor: storefront?.storefront?.style?.cardBackground,
          }}
        >
          <div
            style={{
              backgroundColor: storefront?.storefront?.style?.cardBackground,
            }}
          >
            {order?.total == 0 ? (
              <NoPayCheckoutForm
                order={order}
                storefront={storefront?.storefront}
                stripeOnboard={storefront?.stripeOnboard}
                setReadyForPayment={setReadyForPayment}
                handleSetReadyForPayment={handleSetReadyForPayment}
              />
            ) : (
              <Elements stripe={stripeLoader} options={stripeOptions}>
                <CheckoutForm
                  order={order}
                  storefront={storefront.storefront}
                  stripeOnboard={storefront.stripeOnboard}
                  setReadyForPayment={setReadyForPayment}
                  refetch={refetch}
                  handleSetReadyForPayment={handleSetReadyForPayment}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default Checkout;
