import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import DigitalSuccessMobile from './DigitalCheckout/DigitalSuccessMobile';
import { FaChevronLeft } from 'react-icons/fa';

const DigitalSuccess = ({ order, storefront }) => {
  const btnStyle =
    storefront?.storefront?.style?.buttonStyle === 'filled'
      ? storefront?.storefront?.style?.buttonColor
      : '';

  return isMobile ? (
    <DigitalSuccessMobile order={order} storefront={storefront} />
  ) : (
    <div
      className='w-full mx-auto h-screen'
      style={{
        backgroundColor: storefront?.storefront?.style?.pageBackground,
      }}
    >
      <div
        className='w-full mx-auto h-screen'
        style={{
          backgroundColor: storefront?.storefront?.style?.pageBackground,
        }}
      >
        <div className='mx-auto max-w-7xl'>
          <div className='w-10/12 mx-auto flex justify-start'>
            <Link
              to='/'
              className='flex items-center text-lg font-medium mt-20 mb-2'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              <FaChevronLeft />
              Back to store
            </Link>
          </div>
          <div className='w-10/12 mx-auto h-40  flex flex-col'>
            <div
              className='flex flex-col p-2 border rounded bg-inherit drop-shadow-lg'
              style={{
                borderColor: storefront?.storefront?.style?.borderColor,
                backgroundColor: storefront?.storefront?.style?.cardBackground,
              }}
            >
              <p
                className='md:text-4xl text-3xl mb-4'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Thank you!
              </p>
              <p
                className='md:text-xl'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Order <span className='font-medium'>{order._id}</span> has been
                successful! Access your content below and we sent a download
                link to <span className='font-medium'>{order.email}</span>
              </p>
            </div>

            <div
              className='border bg-inherit rounded mt-4 flex justify-between p-2 drop-shadow-lg'
              style={{
                borderColor: storefront?.storefront?.style?.borderColor,
                backgroundColor: storefront?.storefront?.style?.cardBackground,
              }}
            >
              <div className='w-6/12'>
                <p
                  className='mb-2 font-medium md:text-xl'
                  style={{ color: storefront?.storefront?.style?.pageText }}
                >
                  Estimated arrival:
                </p>

                <p
                  className='md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Immediately
                </p>
              </div>
              <div className='w-6/12 flex flex-col'>
                <p
                  style={{ color: storefront?.storefront?.style?.pageText }}
                  className='mb-2 font-medium md:text-xl'
                >
                  Shipped to the inbox of:
                </p>
                <p
                  style={{ color: storefront?.storefront?.style?.pageText }}
                  className='md:text-xl'
                >
                  {order?.email}
                </p>
              </div>
            </div>

            <div
              className='border bg-inherit drop-shadow-lg rounded mt-4 p-2'
              style={{
                borderColor: storefront?.storefront?.style?.borderColor,
                backgroundColor: storefront?.storefront?.style?.cardBackground,
              }}
            >
              <p
                className='font-medium md:text-xl'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Receipt
              </p>
              <div
                className='flex justify-between pl-10 pr-10 mt-4 border-b mb-4'
                style={{
                  borderColor: storefront?.storefront?.style?.borderColor,
                }}
              >
                <div className='text-left w-6/12'>
                  <p
                    className='md:text-xl'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    Item:
                  </p>

                  <p
                    className='mt-2 md:text-xl'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    Number of files:
                  </p>

                  <p
                    className='font-medium mt-2 md:text-xl'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    Total:
                  </p>
                </div>

                <div className='text-right w-6/12'>
                  <p
                    className='md:text-xl'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    {order?.item?.title}
                  </p>

                  <p
                    className='mt-2 md:text-xl'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    {order?.item?.files.length == 1
                      ? '1 file'
                      : `${order?.item?.files?.length} files`}
                  </p>

                  <p
                    className='font-medium mt-2 md:text-xl'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    ${order?.total.toFixed(2)}
                  </p>
                </div>
              </div>

              <a
                href={`https://fruntt.com/order/${order?._id}`}
                target='_blank'
              >
                <button
                  type='button'
                  className='w-full h-14 border-2 rounded text-xl flex items-center justify-center'
                  style={{
                    color: storefront?.storefront?.style?.buttonTextColor,
                    backgroundColor: btnStyle,
                    borderColor: storefront?.storefront?.style?.buttonColor,
                  }}
                >
                  <p className='mr-2 text-sm'>Access Content Here</p>{' '}
                  <FaExternalLinkAlt className='text-sm' />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalSuccess;
