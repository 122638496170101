import React from 'react';
import moment from 'moment';

//mui
import Rating from '@mui/material/Rating';
import { isMobile } from 'react-device-detect';

const Reviews = ({ storefront, reviews }) => {
  return isMobile ? (
    <div>
      <div
        className='w-full border-b'
        style={{ borderColor: storefront?.style?.borderColor }}
      >
        <p
          className='text-sm mt-4'
          style={{ color: storefront?.style?.headerColor }}
          id='reviews'
        >
          Reviews {''} ({reviews?.length})
        </p>
      </div>

      {reviews?.length > 0 ? (
        <div className='h-56 overflow-y-scroll'>
          {reviews.map((review) => (
            <div
              className='flex flex-col p-4 rounded mt-2 relative'
              style={{ backgroundColor: storefront?.style?.reviewBackground }}
            >
              <div className='absolute right-0 mr-4'>
                <p className='text-xs'>
                  {moment(review?.reviewedOn).format('MMM D, YYYY')}
                </p>
              </div>
              <div className='flex w-full items-center'>
                <p className='text-sm'>{review?.name}</p>
                <Rating
                  value={review.rating}
                  readOnly
                  size='small'
                  className='ml-2'
                  precision={0.5}
                />
              </div>

              <p className='text-md mt-2'>{review.review}</p>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{ backgroundColor: storefront?.style?.reviewBackground }}
          className='w-full h-32 mt-4 rounded flex justify-center items-center'
        >
          <p
            className='font-medium text-sm'
            style={{ color: storefront?.style?.pageText }}
          >
            No reviews
          </p>
        </div>
      )}
    </div>
  ) : (
    <div>
      <div
        className='w-full border-b'
        style={{ borderColor: storefront?.style?.borderColor }}
      >
        <p
          className='text-sm mt-4'
          style={{ color: storefront?.style?.headerColor }}
          id='reviews'
        >
          Reviews {''} ({reviews?.length})
        </p>
      </div>

      {reviews?.length > 0 ? (
        <div className='h-96 overflow-y-scroll'>
          {reviews.map((review) => (
            <div
              className='flex flex-col p-4 rounded mt-2 relative'
              style={{ backgroundColor: storefront?.style?.reviewBackground }}
            >
              <div className='absolute right-0 mr-4'>
                <p className='text-sm md:text-lg'>
                  {moment(review?.reviewedOn).format('MMM D, YYYY')}
                </p>
              </div>
              <div className='flex w-full items-center'>
                <p className='text-lg'>{review?.name}</p>
                <Rating
                  value={review.rating}
                  readOnly
                  size='small'
                  className='ml-2'
                  precision={0.5}
                />
              </div>

              <p className='md:text-xl mt-2'>{review.review}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className='w-full h-32 mt-4 rounded-md flex justify-center items-center bg-gray-100'>
          <p
            className='font-medium text-md'
            style={{ color: storefront?.style?.pageText }}
          >
            No reviews
          </p>
        </div>
      )}
    </div>
  );
};

export default Reviews;
