import { apiSlice } from './apiSlice';

export const itemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStoreItem: builder.query({
      query: () => `/products/store/${process.env.REACT_APP_STORE_ID}`,
    }),
    //submits review data to server endpoint
    submitReview: builder.mutation({
      query: ({ content, rating, customerId }) => ({
        url: '/customers/addreview',
        method: 'POST',
        body: {
          content: content,
          rating: rating,
          customerId: customerId,
        },
      }),
    }),
    getCustomer: builder.query({
      query: (customerId) => `/customers/singlecustomer/${customerId}`,
    }),
  }),
});

export const {
  useGetStoreItemQuery,
  useGetCustomerQuery,
  useSubmitReviewMutation,
} = itemApiSlice;
