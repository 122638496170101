import React from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import moment from 'moment';
import Footer from '../Footer';

const MobileSuccess = ({ order, storefront }) => {
  return (
    <div
      className='w-full mx-auto h-screen'
      style={{
        backgroundColor: storefront?.storefront?.style?.pageBackground,
      }}
    >
      <div className='mx-auto max-w-7xl'>
        <div className='w-10/12 mx-auto flex justify-end'>
          <Link
            to='/'
            className='flex items-center text-lg font-medium mt-10'
            style={{ color: storefront?.storefront?.style?.pageText }}
          >
            Back to shopping
            <BsArrowRightShort />
          </Link>
        </div>
        <div className='w-10/12 mx-auto h-40  flex flex-col'>
          <div
            className='flex flex-col p-2 border-2 rounded'
            style={{
              borderColor: storefront?.storefront?.style?.borderColor,
            }}
          >
            <p
              className='md:text-4xl text-3xl mb-4'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              Thanks for your order!
            </p>
            <p
              className='md:text-xl'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              Order <span className='font-medium'>{order._id}</span> is in the
              works! We sent an email to{' '}
              <span className='font-medium'>{order.email}</span> with all the
              order details.
            </p>
          </div>

          <div
            className='border-2 rounded mt-4 flex flex-col p-2'
            style={{
              borderColor: storefront?.storefront?.style?.borderColor,
            }}
          >
            <p
              className='mb-2 font-medium md:text-xl'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              Estimated arrival:
            </p>
            <p
              className='text-lg'
              style={{
                color: storefront?.storefront?.style?.pageText,
              }}
            >
              Tracking link sent by email
            </p>

            <div className='w-full flex flex-col mt-2'>
              <p
                style={{ color: storefront?.storefront?.style?.pageText }}
                className='font-medium'
              >
                Shipping to:
              </p>
              <p
                style={{ color: storefront?.storefront?.style?.pageText }}
                className='text-lg mt-2'
              >
                {order?.firstName} {order?.lastName}
              </p>
              <p
                style={{ color: storefront?.storefront?.style?.pageText }}
                className='text-lg'
              >
                {order?.shippingAddress?.address}
              </p>
              <p
                style={{ color: storefront?.storefront?.style?.pageText }}
                className='text-lg'
              >
                {order?.shippingAddress?.city}, {order?.shippingAddress?.state}{' '}
                {order?.shippingAddress?.zipcode} <br />
                United States
              </p>
            </div>
          </div>

          <div
            className='border-2 rounded mt-4 p-2'
            style={{
              borderColor: storefront?.storefront?.style?.borderColor,
            }}
          >
            <p
              className='font-medium md:text-xl'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              Receipt:
            </p>
            <div className='flex justify-between pl-2 pr-2 mt-4'>
              <div className='text-left w-4/12'>
                <p
                  className='md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Item:
                </p>
                {Object.entries(order?.item?.options).length > 0 ? (
                  <p
                    className='md:text-xl mt-2'
                    style={{
                      color: storefront?.storefront?.style?.pageText,
                    }}
                  >
                    Options:
                  </p>
                ) : (
                  ''
                )}
                <p
                  className='mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Price:
                </p>
                <p
                  className='mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Quantity:
                </p>
                <p
                  className='mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Shipping:
                </p>
                <p
                  className='font-medium mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Total:
                </p>
              </div>

              <div className='text-right w-8/12'>
                <p
                  className='md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  {order?.item?.title}
                </p>
                <div className='mt-2'>
                  {Object.entries(order.options).map(([key, value]) => (
                    <p>
                      {key}: {value}
                    </p>
                  ))}
                </div>
                <p
                  className='mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  ${order?.item?.price}
                </p>
                <p
                  className='mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  x {order?.qty}
                </p>
                <p
                  className='mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  ${order?.item?.shippingPrice.toFixed(2)}
                </p>
                <p
                  className='font-medium mt-2 md:text-xl'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  ${order?.total.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSuccess;
