import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Checkout from './Checkout';
import Reviews from '../components/Reviews';
import { useCreateOrderMutation } from '../api/orderApiSlice';
import {
  MdOutlineFileDownload,
  MdOutlineVideoLibrary,
  MdLocalPrintshop,
  MdOutlinePermMedia,
} from 'react-icons/md';
import { FaRegSadTear } from 'react-icons/fa';
import { HiOutlineBookOpen, HiOutlineTemplate } from 'react-icons/hi';
import { BsFillMicFill, BsPalette } from 'react-icons/bs';
import ProductDesc from '../components/ProductDesc';
import Cookies from 'js-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import { Carousel } from 'flowbite-react';

//mui
import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';

const DetailMobile = ({ storefront, products, error, setError }) => {
  const { productUrl } = useParams();
  const navigate = useNavigate();
  const orderId = Cookies.get('orderId') ? Cookies.get('orderId') : null;

  const btnStyle =
    storefront?.storefront?.style?.buttonStyle === 'filled'
      ? storefront?.storefront?.style?.buttonColor
      : '';

  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [priceChoice, setPriceChoice] = useState(0);
  const visited = localStorage.getItem('visited')
    ? localStorage.getItem('visited')
    : null;

  const btnDisabled = selectedProduct?.item?.free
    ? false
    : storefront?.stripeOnboard
    ? false
    : true;

  const [createOrder, { isSuccess: createdOrder }] = useCreateOrderMutation();

  const handleSetReadyForPayment = () => {
    setReadyForPayment(false);
    setPriceChoice(0);
    Cookies.remove('orderId');
  };

  const handleSelectedProduct = (e) => {
    const productId = e.value;
    const selected = products.find((product) => product.item._id === productId);

    setSelectedProduct(selected);

    setError('');

    navigate(`/${selected?.item?.url}`);
  };

  const handleMoveToCheckout = async (e) => {
    e.preventDefault();

    if (
      selectedProduct?.item?.payChoice &&
      priceChoice < selectedProduct?.item?.price
    ) {
      setError(`Price has to be $${selectedProduct?.item?.price} or more!`);
      return;
    }

    if (orderId) {
      setReadyForPayment(true);
      // navigate(`/checkout/${orderId}`);
    } else {
      try {
        const createOrderReq = await createOrder({
          total: selectedProduct?.item?.payChoice
            ? priceChoice
            : selectedProduct?.item?.price,
          item: selectedProduct?.item,
        }).unwrap();
        Cookies.set('orderId', createOrderReq.orderId);
        // navigate(`/checkout/${createOrderReq.orderId}`);
        setReadyForPayment(true);
      } catch (err) {
        setError('There was a server error');
      }
    }
  };

  useEffect(() => {
    const setInitialProduct = (e) => {
      const selected = products.find(
        (product) => product.item.url === productUrl
      );

      setSelectedProduct(selected);
    };

    if (productUrl) setInitialProduct();
  }, []);

  useEffect(() => {
    setError('');
  }, [priceChoice]);

  useEffect(() => {
    setError('');
    setPriceChoice(0);
  }, []);

  console.log(selectedProduct?.item.coverImages);

  return (
    <>
      <Header
        storefront={storefront?.storefront}
        sellerProfile={storefront?.sellerProfile}
        storeIds={storefront?.storeIds}
        products={products}
        handleSelectedProduct={handleSelectedProduct}
        selectedProduct={selectedProduct}
      />

      {products.length > 0 ? (
        <div
          className='w-full p-2'
          style={{
            backgroundColor: storefront?.storefront?.style?.pageBackground,
          }}
        >
          <div
            className='flex flex-col max-w-6xl mx-auto h-fit p-4 border rounded drop-shadow-md bg-inherit'
            style={{
              backgroundColor: storefront?.storefront?.style?.cardBackground,
              borderColor: storefront?.storefront?.style?.borderColor,
            }}
          >
            <div className='w-full mx-auto mt-4'>
              <div className='h-56 sm:h-64 xl:h-80 2xl:h-96'>
                {/* <img
                  src={selectedProduct?.item?.coverImages[0]?.url}
                  style={{
                    borderColor: storefront?.storefront?.style?.borderColor,
                  }}
                  className='rounded-md border w-full h-full object-fill'
                /> */}

                {selectedProduct?.item?.coverImages.length ? (
                  <Carousel>
                    {selectedProduct?.item?.coverImages.map((img, index) => (
                      <div key={index}>
                        <img
                          src={img?.url}
                          className='rounded-md object-fill h-full w-full'
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <img
                    src={selectedProduct?.item?.coverImage?.url}
                    className='rounded-md border w-full h-full object-fill'
                  />
                )}
              </div>
              {readyForPayment ? (
                <Checkout
                  orderId={orderId}
                  storefront={storefront}
                  setReadyForPayment={setReadyForPayment}
                  handleSetReadyForPayment={handleSetReadyForPayment}
                />
              ) : (
                <div className='w-full flex flex-col md:pl-10 mt-2'>
                  {error ? (
                    <Alert severity='error' className='w-full'>
                      {error}
                    </Alert>
                  ) : (
                    ''
                  )}
                  <p
                    className='text-lg font-bold mt-4 md:mt-0'
                    style={{ color: storefront?.storefront?.style?.pageText }}
                  >
                    {selectedProduct?.item?.title}
                  </p>
                  <p
                    className='text-md mt-2 mb-2'
                    style={{ color: storefront?.storefront?.style?.pageText }}
                  >
                    {selectedProduct?.item?.description}
                  </p>

                  <form>
                    <div className='flex items-center mt-4'>
                      <div
                        className='flex justify-between items-center border rounded p-2'
                        style={{
                          borderColor: storefront?.storefront?.style?.price,
                          backgroundColor: storefront?.storefront?.style?.price,
                        }}
                      >
                        <p
                          className='text-lg font-medium'
                          style={{
                            color: storefront?.storefront?.style?.pageText,
                          }}
                        >
                          {selectedProduct?.item?.free
                            ? 'FREE'
                            : selectedProduct?.item?.payChoice
                            ? `$ ${selectedProduct?.item?.price?.toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              )} +`
                            : `$${selectedProduct?.item?.price?.toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              )}`}
                        </p>
                      </div>

                      <div className='flex items-center ml-6'>
                        <Rating
                          value={selectedProduct?.totalRating}
                          readOnly
                          precision={0.5}
                        />
                        <a
                          href='#reviews'
                          style={{
                            color: storefront?.storefront?.style?.pageText,
                          }}
                        >
                          <p className='ml-2'>
                            {selectedProduct?.reviews?.length
                              ? `(${selectedProduct?.reviews?.length}) reviews`
                              : '(0) reviews'}
                          </p>
                        </a>
                      </div>
                    </div>

                    <div className='w-full flex justify-between items-center mt-4'>
                      {selectedProduct?.item?.payChoice ? (
                        <div className='flex flex-col w-full'>
                          <p className='text-sm font-medium'>Set your price</p>
                          <div className='w-full flex mt-1'>
                            <div className='flex items-center w-3/12'>
                              <div className=''>
                                <p className='font-medium mr-1 text-lg'>$</p>
                              </div>
                              <input
                                type='number'
                                className='border-2 mr-2 rounded-md h-10 bg-transparent p-2 outline outline-0 w-full shadow-md'
                                style={{
                                  borderColor:
                                    storefront?.storefront?.style?.borderColor,
                                  WebkitAppearance: 'none',
                                  MozAppearance: 'textfield',
                                }}
                                placeholder={`$${selectedProduct?.item?.suggestedPrice} +`}
                                min={selectedProduct?.item?.price}
                                onChange={(e) => setPriceChoice(e.target.value)}
                              />
                            </div>
                            <button
                              disabled={btnDisabled}
                              onClick={handleMoveToCheckout}
                              style={{
                                color:
                                  storefront?.storefront?.style
                                    ?.buttonTextColor,
                                backgroundColor: btnStyle,
                                borderColor:
                                  storefront?.storefront?.style?.buttonColor,
                              }}
                              className='w-9/12 h-10 text-sm border-2 rounded-md shadow-md'
                            >
                              {selectedProduct?.item?.callToAction === 'buy'
                                ? 'Buy Now'
                                : selectedProduct?.item?.callToAction === 'want'
                                ? 'I want this!'
                                : 'Get Now'}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <button
                          disabled={btnDisabled}
                          onClick={handleMoveToCheckout}
                          style={{
                            color:
                              storefront?.storefront?.style?.buttonTextColor,
                            backgroundColor: btnStyle,
                            borderColor:
                              storefront?.storefront?.style?.buttonColor,
                          }}
                          className='w-full h-10 text-sm border-2 rounded-md shadow-md'
                        >
                          {selectedProduct?.item?.callToAction === 'buy'
                            ? 'Buy Now'
                            : selectedProduct?.item?.callToAction === 'want'
                            ? 'I want this!'
                            : 'Get Now'}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>

            {storefront?.storefront?.hideDescription ? (
              ''
            ) : (
              <ProductDesc
                product={selectedProduct?.item}
                storefront={storefront.storefront}
              />
            )}

            {/* {storefront?.storefront?.hideQuestions ? (
              ''
            ) : (
              <FAQs
                faqs={selectedProduct?.item?.faqs}
                storefront={storefront.storefront}
              />
            )} */}

            {storefront?.storefront?.hideReviews ? (
              ''
            ) : (
              <Reviews
                storefront={storefront.storefront}
                reviews={selectedProduct?.reviews}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className='h-screen mx-auto p-2'
          style={{
            backgroundColor: storefront?.storefront?.style?.pageBackground,
          }}
        >
          <div
            className='w-full max-w-6xl h-5/6 flex flex-col justify-center items-center rounded border bg-inherit drop-shadow-lg mx-auto p-2'
            style={{
              borderColor: storefront?.storefront?.style?.borderColor,
              backgroundColor: storefront?.storefront?.style?.cardBackground,
            }}
          >
            <p
              className='text-xl text-center'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              This product page is not offering anything at the moment!
            </p>
            <FaRegSadTear className='text-3xl mt-4' />
            <p
              className='text-lg'
              style={{ color: storefront?.storefront?.style?.pageText }}
            ></p>
          </div>
        </div>
      )}
      <Footer storefront={storefront.storefront} />
    </>
  );
};

export default DetailMobile;
