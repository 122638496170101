import React from 'react';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { BsArrowRightShort } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';

const DigitalSuccessMobile = ({ storefront, order }) => {
  const btnStyle =
    storefront?.storefront?.style?.buttonStyle === 'filled'
      ? storefront?.storefront?.style?.buttonColor
      : '';

  return (
    <div
      className='w-full mx-auto h-screen p-2'
      style={{
        backgroundColor: storefront?.storefront?.style?.pageBackground,
      }}
    >
      <div
        className='w-full mx-auto h-screen'
        style={{
          backgroundColor: storefront?.storefront?.style?.pageBackground,
        }}
      >
        <div className='mx-auto w-full'>
          <div className='w-full mx-auto flex justify-start'>
            <Link
              to='/'
              className='flex items-center text-lg font-medium mt-20'
              style={{ color: storefront?.storefront?.style?.pageText }}
            >
              <FaChevronLeft className='text-sm' />
              Back to store
            </Link>
          </div>
          <div className='w-full mx-auto h-40  flex flex-col'>
            <div
              className='flex flex-col p-2 border bg-inherit drop-shadow-md rounded'
              style={{
                borderColor: storefront?.storefront?.style?.borderColor,
                backgroundColor: storefront?.storefront?.style?.cardBackground,
              }}
            >
              <p
                className='md:text-4xl text-3xl mb-4'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Thank you!
              </p>
              <p
                className='md:text-xl'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Order <span className='font-medium'>{order._id}</span> has been
                successful! Access your content below and we sent a download
                link to <span className='font-medium'>{order.email}</span>
              </p>
            </div>

            <div
              className='border bg-inherit drop-shadow-md rounded mt-4 flex flex-col p-2'
              style={{
                borderColor: storefront?.storefront?.style?.borderColor,
                backgroundColor: storefront?.storefront?.style?.cardBackground,
              }}
            >
              <p
                className='font-medium'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Estimated arrival:
              </p>

              <p
                className='text-lg'
                style={{
                  color: storefront?.storefront?.style?.pageText,
                }}
              >
                Immediately
              </p>

              <p
                style={{ color: storefront?.storefront?.style?.pageText }}
                className='mt-2 font-medium'
              >
                Shipped to the inbox of:
              </p>
              <p
                style={{ color: storefront?.storefront?.style?.pageText }}
                className='text-lg'
              >
                {order?.email}
              </p>
            </div>

            <div
              className='border bg-inherit drop-shadow-md rounded mt-4 p-2'
              style={{
                borderColor: storefront?.storefront?.style?.borderColor,
                backgroundColor: storefront?.storefront?.style?.cardBackground,
              }}
            >
              <p
                className='font-medium text-lg'
                style={{ color: storefront?.storefront?.style?.pageText }}
              >
                Receipt
              </p>
              <div
                className='flex flex-col mt-4 border-b mb-4'
                style={{
                  borderColor: storefront?.storefront?.style?.borderColor,
                }}
              >
                <p
                  className='font-medium'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Item:
                </p>
                <p
                  className='text-lg'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  {order?.item?.title}
                </p>

                <p
                  className='mt-2 font-medium'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Number of files:
                </p>
                <p
                  className='text-lg'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  {order?.item?.files.length == 1
                    ? `${order?.item?.files?.length} file`
                    : `${order?.item?.files?.length} files`}
                </p>

                <p
                  className='font-medium mt-2'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  Total:
                </p>

                <p
                  className='text-2xl font-medium'
                  style={{
                    color: storefront?.storefront?.style?.pageText,
                  }}
                >
                  ${order?.total.toFixed(2)}
                </p>
              </div>

              <a
                href={`https://fruntt.com/order/${order?._id}`}
                target='_blank'
              >
                <button
                  type='button'
                  className='w-full h-14 border-2 rounded text-lg flex items-center justify-center'
                  style={{
                    color: storefront?.storefront?.style?.buttonTextColor,
                    backgroundColor: btnStyle,
                    borderColor: storefront?.storefront?.style?.buttonColor,
                  }}
                >
                  <p className='mr-2 text-sm'>Access Content Here</p>{' '}
                  <FaExternalLinkAlt className='text-sm' />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalSuccessMobile;
