import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiOutlineLink,
} from 'react-icons/ai';
import { FaTiktok } from 'react-icons/fa';
import Select from 'react-select';

//mui
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const Header = ({
  storefront,
  sellerProfile,
  products,
  selectedProduct,
  handleSelectedProduct,
}) => {
  const [pageUrl, setPageUrl] = useState('');

  return isMobile ? (
    <div
      className='w-full mx-auto p-2'
      style={{
        backgroundColor: storefront?.style?.pageBackground,
      }}
    >
      <div
        className='max-w-6xl flex flex-col mx-auto border rounded-md bg-inherit drop-shadow-lg p-4'
        style={{
          borderColor: storefront?.style?.borderColor,
          backgroundColor: storefront?.style?.cardBackground,
        }}
      >
        <div className='flex items-center mt-4'>
          <Link to='/'>
            <Avatar
              sx={{ width: 62, height: 62 }}
              src={sellerProfile?.profilePic}
              style={{
                borderColor: storefront?.style?.borderColor,
              }}
              className='border'
            />
          </Link>

          <div
            className='flex flex-col ml-2 border-l pl-2'
            style={{ borderColor: storefront?.style?.borderColor }}
          >
            <p
              className='text-2xl font-medium'
              style={{ color: storefront?.style?.pageText }}
            >
              {sellerProfile?.name}
            </p>

            <p style={{ color: storefront?.style?.pageText }}>
              {sellerProfile?.bio ? sellerProfile?.bio : 'This bio is empty'}
            </p>
          </div>
        </div>

        <div className='flex justify-between items-center w-full mt-2'>
          <div className='flex flex-col w-8/12'>
            <div className='flex flex-col w-8/full relative z-50'>
              <p
                className='text-sm'
                style={{ color: storefront?.style?.pageText }}
              >
                My templates:
              </p>
              {/* <select
                onChange={handleSelectedProduct}
                className='rounded border-2 bg-transparent h-8 pl-2'
                style={{
                  borderColor: storefront?.style?.borderColor,
                  color: storefront?.style?.pageText,
                }}
                value={selectedProduct ? selectedProduct?.item?._id : ''}
              >
                {products.map((prod) => (
                  <option value={prod?.item?._id}>{prod?.item?.title}</option>
                ))}
                <option disabled>More coming!</option>
              </select> */}

              <Select
                value={selectedProduct?.item?._id}
                options={products.map((product) => ({
                  value: product?.item?._id,
                  label: product?.item?.title,
                }))}
                onChange={handleSelectedProduct}
                placeholder={selectedProduct?.item?.title}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: storefront?.style?.borderColor,
                    '&:hover': {
                      borderColor: storefront?.style?.borderColor, // Keep the same border color on hover
                    },
                    boxShadow: 'none',
                    zIndex: 99999,
                    position: 'relative',
                  }),
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            <div className='flex items-center justify-start text-2xl mt-4'>
              {sellerProfile?.facebook && (
                <a href={sellerProfile?.facebook} target='_blank'>
                  <AiOutlineFacebook
                    style={{ color: storefront?.style?.pageText }}
                  />
                </a>
              )}
              {sellerProfile?.instagram && (
                <a href={sellerProfile?.instagram} target='_blank'>
                  <AiOutlineInstagram
                    style={{ color: storefront?.style?.pageText }}
                    className=' ml-2'
                  />
                </a>
              )}
              {sellerProfile?.twitter && (
                <a href={sellerProfile?.twitter} target='_blank'>
                  <AiOutlineTwitter
                    style={{ color: storefront?.style?.pageText }}
                    className='ml-2'
                  />
                </a>
              )}
              {sellerProfile?.linkedin && (
                <a href={sellerProfile?.linkedin} target='_blank'>
                  <AiOutlineLinkedin className='ml-2' />
                </a>
              )}
              {sellerProfile?.youtube && (
                <a href={sellerProfile?.youtube} target='_blank'>
                  <AiOutlineYoutube
                    style={{ color: storefront?.style?.pageText }}
                    className='text-gray-400 hover:text-blue-400 ml-2'
                  />
                </a>
              )}
              {sellerProfile?.tiktok && (
                <a href={sellerProfile?.tiktok} target='_blank'>
                  <FaTiktok
                    style={{ color: storefront?.style?.pageText }}
                    className='text-gray-400 hover:text-blue-400 ml-2'
                  />
                </a>
              )}
              {sellerProfile?.link && (
                <a href={sellerProfile?.link} target='_blank'>
                  <AiOutlineLink
                    style={{ color: storefront?.style?.pageText }}
                    className='text-gray-400 hover:text-blue-400 ml-2'
                  />
                </a>
              )}
            </div>
          </div>
          <div className='flex flex-col items-center w-3/12'>
            <p
              className='font-medium text-3xl'
              style={{ color: storefront?.style?.pageText }}
            >
              {sellerProfile?.numberOfSales > 0
                ? sellerProfile.numberOfSales
                : '0'}{' '}
            </p>
            <p
              style={{ color: storefront?.style?.pageText }}
              className='text-sm'
            >
              {sellerProfile?.numberOfSales == 1 ? 'Sale' : 'Sales'}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className='w-full mx-auto pt-10'
      style={{
        backgroundColor: storefront?.style?.pageBackground,
        display: storefront?.style?.hideNav ? 'none' : '',
      }}
    >
      <div
        className='max-w-6xl flex items-center justify-between mx-auto p-4 border rounded-md drop-shadow-md bg-white'
        style={{
          borderColor: storefront?.style?.borderColor,
          backgroundColor: storefront?.style?.cardBackground,
        }}
      >
        <Link to='/' onClick={() => Cookies.remove('orderId')}>
          <Avatar
            sx={{ width: 62, height: 62 }}
            src={sellerProfile?.profilePic}
            style={{
              borderColor: storefront?.style?.borderColor,
            }}
            className='border'
          />
        </Link>

        <div
          className='flex flex-col w-4/12 border-l-2 pl-2'
          style={{ borderColor: storefront?.style?.borderColor }}
        >
          <p
            className='text-2xl font-medium'
            style={{ color: storefront?.style?.pageText }}
          >
            {sellerProfile?.name}
          </p>

          <p style={{ color: storefront?.style?.pageText }}>
            {sellerProfile?.bio ? sellerProfile?.bio : 'This bio is empty'}
          </p>
        </div>

        <div className='flex flex-col items-center w-1/12'>
          <p
            className='font-medium text-3xl'
            style={{ color: storefront?.style?.pageText }}
          >
            {sellerProfile?.numberOfSales ? sellerProfile.numberOfSales : '0'}{' '}
          </p>
          <p style={{ color: storefront?.style?.pageText }} className='text-sm'>
            {sellerProfile?.numberOfSales == 1 ? 'Sale' : 'Sales'}
          </p>
        </div>

        <div className='flex flex-col w-3/12'>
          <p
            className='font-medium'
            style={{ color: storefront?.style?.pageText }}
          >
            My templates:
          </p>

          <Select
            value={selectedProduct?.item?._id}
            options={products.map((product) => ({
              value: product?.item?._id,
              label: product?.item?.title,
            }))}
            onChange={handleSelectedProduct}
            placeholder={selectedProduct?.item?.title}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: storefront?.style?.borderColor,
                '&:hover': {
                  borderColor: storefront?.style?.borderColor, // Keep the same border color on hover
                },
                boxShadow: 'none',

                zIndex: 99999,
                position: 'relative',
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
          />
          {/* <select
            className='rounded border-2 bg-transparent h-8 w-11/12'
            style={{
              borderColor: storefront?.style?.borderColor,
              color: storefront?.style?.pageText,
            }}
            onChange={handleRedirect}
          >
            <option selected disabled>
              {storefront.url}
            </option>
            {filteredStoreIds.map((store) => (
              <option value={store.url}>{store?.url}</option>
            ))}
            <option disabled>More coming!</option>
          </select> */}
        </div>
        <div className='flex items-center text-3xl justify-end w-2/12'>
          {sellerProfile?.facebook && (
            <a href={sellerProfile?.facebook} target='_blank'>
              <AiOutlineFacebook
                style={{ color: storefront?.style?.pageText }}
              />
            </a>
          )}
          {sellerProfile?.instagram && (
            <a href={sellerProfile?.instagram} target='_blank'>
              <AiOutlineInstagram
                style={{ color: storefront?.style?.pageText }}
                className=' ml-2'
              />
            </a>
          )}
          {sellerProfile?.twitter && (
            <a href={sellerProfile?.twitter} target='_blank'>
              <AiOutlineTwitter
                style={{ color: storefront?.style?.pageText }}
                className=' ml-2'
              />
            </a>
          )}
          {sellerProfile?.linkedin && (
            <a href={sellerProfile?.linkedin} target='_blank'>
              <AiOutlineLinkedin className='ml-2' />
            </a>
          )}
          {sellerProfile?.youtube && (
            <a href={sellerProfile?.youtube} target='_blank'>
              <AiOutlineYoutube
                style={{ color: storefront?.style?.pageText }}
                className='text-gray-400 hover:text-blue-400 ml-2'
              />
            </a>
          )}
          {sellerProfile?.tiktok && (
            <a href={sellerProfile?.tiktok} target='_blank'>
              <FaTiktok
                style={{ color: storefront?.style?.pageText }}
                className='text-gray-400 hover:text-blue-400 ml-2'
              />
            </a>
          )}
          {sellerProfile?.link && (
            <a href={sellerProfile?.link} target='_blank'>
              <AiOutlineLink
                style={{ color: storefront?.style?.pageText }}
                className='text-gray-400 hover:text-blue-400 ml-2'
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
