import React from 'react';
import { isMobile } from 'react-device-detect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const ProductDesc = ({ product, storefront }) => {
  const customQuillClass = 'custom-quill';

  return product?.info === '' ? (
    <div>
      {isMobile ? (
        <>
          <div
            className='w-full border-b'
            style={{ borderColor: storefront?.style?.borderColor }}
          >
            <p
              className='text-sm text-gray-400 mt-4'
              style={{ color: storefront?.style?.headerColor }}
            >
              Description
            </p>
          </div>
          <div
            style={{ backgroundColor: '#f3f3f3' }}
            className='mx-auto w-full h-44 mt-4 rounded flex justify-center items-center'
          >
            <p
              className='font-medium text-sm text-center'
              style={{ color: storefront?.style?.pageText }}
            >
              No description
            </p>
          </div>
        </>
      ) : (
        <>
          <div
            className='w-full border-b'
            style={{ borderColor: storefront?.style?.borderColor }}
          >
            <p
              className='text-sm text-gray-400 mt-4'
              style={{ color: storefront?.style?.headerColor }}
            >
              Description
            </p>
          </div>
          <div className='mx-auto w-full h-44 mt-4 bg-gray-100 rounded-md flex justify-center items-center'>
            <p
              className='font-medium text-md text-center'
              style={{ color: storefront?.style?.pageText }}
            >
              No description
            </p>
          </div>
        </>
      )}
    </div>
  ) : (
    <div>
      {isMobile ? (
        <>
          <div
            className='w-full border-b'
            style={{ borderColor: storefront?.style?.borderColor }}
          >
            <p
              className='text-sm text-gray-400 mt-4'
              style={{ color: storefront?.style?.headerColor }}
            >
              Description
            </p>
          </div>
          <ReactQuill
            value={product?.info}
            readOnly={true}
            theme={'bubble'}
            className={customQuillClass}
          />{' '}
        </>
      ) : (
        <>
          {' '}
          <div
            className='w-full border-b'
            style={{ borderColor: storefront?.style?.borderColor }}
          >
            <p
              className='text-sm text-gray-400 mt-4'
              style={{ color: storefront?.style?.headerColor }}
            >
              Description
            </p>
          </div>
          <ReactQuill
            value={product?.info}
            readOnly={true}
            theme={'bubble'}
            className={customQuillClass}
          />
        </>
      )}
    </div>
  );
};

export default ProductDesc;
