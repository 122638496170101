import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateOrderMutation,
  useUpdateOrderAmountMutation,
} from '../api/orderApiSlice';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import DigitalCheckoutMobile from '../components/DigitalCheckout/DigitalCheckoutMobile';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import Select from 'react-select';
import countryList from 'react-select-country-list';

//stripe
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

//mui
import Alert from '@mui/material/Alert';

const NoPayCheckoutForm = ({
  order,
  storefront,
  stripeOnboard,
  setReadyForPayment,
  handleSetReadyForPayment,
  refetch,
}) => {
  const navigate = useNavigate();

  const options = useMemo(() => countryList().getData(), []);

  //form state
  const [name, setName] = useState('');

  const [email, setEmail] = useState('');
  const [country, setCountry] = useState({});
  const [zip, setZip] = useState('');

  const [total, setTotal] = useState(order?.total);
  const [error, setError] = useState('');
  const [placingOrder, setPlacingOrder] = useState(false);

  const btnStyle =
    storefront?.style?.buttonStyle === 'filled'
      ? storefront?.style?.buttonColor
      : '';

  const handleCountry = (value) => {
    setCountry(value);
  };

  // const [updateOrderAmount, result] = useUpdateOrderAmountMutation();
  const [updateOrder, { isLoading }] = useUpdateOrderMutation();

  const handleConfirmOrder = async (e) => {
    e.preventDefault();

    //make sure all fields are filled out
    if (!email || !country) {
      setError('All feilds must be filled out');
      return;
    }

    try {
      setPlacingOrder(true);

      const updatedOrderReq = await updateOrder({
        orderId: order._id,
        email,
        country: country,
        zip: zip,
        name: name,
      }).unwrap();

      //update the order on the server
      if (updatedOrderReq.msg === 'Order updated') {
        setPlacingOrder(false);
        Cookies.remove('orderId');
        navigate(`/order/success/`, {
          state: { order: updatedOrderReq.order },
        });
      } else {
        setPlacingOrder(false);
        setError('There was an error');
        return;
      }
    } catch (err) {
      setPlacingOrder(false);
      setError('There was an error');
      return;
    }
  };

  useEffect(() => {
    setError('');
  }, [name, email, zip]);

  return isMobile ? (
    <div>
      <form onSubmit={handleConfirmOrder} className='flex flex-col'>
        {error && (
          <Alert severity='error' className='w-full'>
            {error}
          </Alert>
        )}
        <div className='w-full'>
          <div className='mb-2 flex flex-col'>
            <p className='text-sm'>Item</p>
            <div
              className='flex border-2 rounded mt-1 shadow-md'
              style={{ borderColor: storefront?.style?.borderColor }}
            >
              <div className='w-10/12 flex justify-center items-center p-1'>
                <p className='text-md text-stone-800'>{order?.item?.title}</p>
              </div>
              <div
                className='w-2/12 flex items-center justify-center border-l-2 rounded-tr rounded-br'
                style={{
                  borderColor: storefront?.style?.borderColor,
                  backgroundColor: storefront?.style?.price,
                }}
              >
                <p className='text-md'>${order?.total}</p>
              </div>
            </div>
            <p className='mt-1 text-sm font-medium'>Deliver to</p>
            <input
              type='email'
              placeholder='Email'
              className='border-2 w-full drop-shadow-md rounded p-2 outline outline-0 h-10 text-sm'
              onChange={(e) => setEmail(e.target.value)}
              style={{
                borderColor: storefront?.style?.borderColor,
              }}
            />

            <input
              type='text'
              placeholder='Name'
              className='border-2 w-full drop-shadow-md rounded p-2 mt-2 outline outline-0 h-10 text-sm'
              onChange={(e) => setName(e.target.value)}
              style={{ borderColor: storefront?.style?.borderColor }}
            />
            <div className='flex items-center mt-2'>
              <Select
                options={options}
                onChange={handleCountry}
                className='w-6/12 h-10 shadow-md text-sm'
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: storefront?.style?.borderColor,
                    '&:hover': {
                      borderColor: storefront?.style?.borderColor, // Keep the same border color on hover
                    },
                    boxShadow: 'none',
                    borderWidth: 2,
                    zIndex: 99999,
                    position: 'relative',
                  }),
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
              <input
                type='text'
                placeholder='ZIP'
                className='border-2 w-6/12 shadow-md rounded p-2 outline outline-0 h-10 ml-2 text-sm'
                onChange={(e) => setZip(e.target.value)}
                pattern='^\d{5}$'
                title='Please enter a 5-digit zip code'
                style={{ borderColor: storefront?.style?.borderColor }}
              />
            </div>
          </div>
        </div>

        <div className='w-full flex items-center'>
          <button
            type='button'
            onClick={handleSetReadyForPayment}
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-3/12 h-12 text-sm border-2 rounded mt-2 mr-2 flex items-center justify-center'
          >
            <FaChevronLeft className='text-sm' />
          </button>
          <button
            disabled={placingOrder}
            type='submit'
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-9/12 h-12 text-sm border-2 rounded mt-2'
          >
            {placingOrder
              ? 'Placing order...'
              : order?.item?.callToAction === 'buy'
              ? 'Buy Now'
              : order?.item?.callToAction === 'want'
              ? 'I want this!'
              : 'Get Now'}
          </button>
        </div>
      </form>
    </div>
  ) : (
    <div>
      <form onSubmit={handleConfirmOrder} className='flex flex-col'>
        {error && (
          <Alert severity='error' className='w-full'>
            {error}
          </Alert>
        )}
        <p className='text-md'>Item</p>
        <div
          className='flex border-2 rounded h-10 mt-1 shadow-md'
          style={{ borderColor: storefront?.style?.borderColor }}
        >
          <div className='w-10/12 flex justify-center items-center'>
            <p className='text-md text-stone-800'>{order?.item?.title}</p>
          </div>
          <div
            className='w-2/12 flex items-center justify-center border-l-2'
            style={{
              borderColor: storefront?.style?.borderColor,
              backgroundColor: storefront?.style?.price,
            }}
          >
            <p className='text-md'>${order?.total}</p>
          </div>
        </div>
        <div className='w-full'>
          <div className='mb-2 flex flex-col'>
            <p className='mt-1' style={{ color: storefront?.style?.textColor }}>
              Deliver to
            </p>
            <input
              type='email'
              placeholder='Email'
              className='border-2 w-full rounded p-2 outline outline-0 h-10 text-sm drop-shadow-md'
              onChange={(e) => setEmail(e.target.value)}
              style={{
                borderColor: storefront?.style?.borderColor,
              }}
            />

            <input
              type='text'
              placeholder='Name'
              className='border-2 w-full drop-shadow-md rounded p-2 mt-2 text-sm outline outline-0 h-10'
              onChange={(e) => setName(e.target.value)}
              style={{ borderColor: storefront?.style?.borderColor }}
            />
            <div className='flex items-center mt-2'>
              <Select
                options={options}
                onChange={handleCountry}
                className='w-6/12 h-10 shadow-md text-sm'
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: storefront?.style?.borderColor,
                    '&:hover': {
                      borderColor: storefront?.style?.borderColor, // Keep the same border color on hover
                    },
                    boxShadow: 'none',
                    borderWidth: 2,
                    zIndex: 99999,
                    position: 'relative',
                  }),
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
              <input
                type='text'
                placeholder='ZIP'
                className='border-2 w-6/12 shadow-md rounded p-2 outline outline-0 h-10 ml-2 text-sm'
                onChange={(e) => setZip(e.target.value)}
                pattern='^\d{5}$'
                title='Please enter a 5-digit zip code'
                style={{ borderColor: storefront?.style?.borderColor }}
              />
            </div>
          </div>
        </div>

        <div className='w-full flex items-center'>
          <button
            type='button'
            onClick={handleSetReadyForPayment}
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-3/12 h-12 text-lg border-2 rounded mt-2 mr-2 flex items-center justify-center'
          >
            <FaChevronLeft className='text-sm' />
          </button>
          <button
            disabled={placingOrder}
            type='submit'
            style={{
              color: storefront?.style?.buttonTextColor,
              backgroundColor: btnStyle,
              borderColor: storefront?.style?.buttonColor,
            }}
            className='w-9/12 h-12 text-sm border-2 rounded mt-2'
          >
            {placingOrder
              ? 'Placing order...'
              : `Place Order - $${order?.total}`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NoPayCheckoutForm;
